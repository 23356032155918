import defaultSettings from '@/settings'

const memberNameY = localStorage.getItem("memberCode") === "yyhl" ? "易易互联" : "木白装备云"

const title = defaultSettings.title || memberNameY
// const title = defaultSettings.title || '木白装备云'

export default function getPageTitle(pageTitle) {
  const a = localStorage.getItem('memberCode')
  if(a === 'yyhl'){
    if (pageTitle) {
      return `${pageTitle} - 易易互联`
    }
    return `易易互联`
  }else {
    if (localStorage.getItem('HL') === '1') {
      return pageTitle || ''
    }
    if (pageTitle) {
      return `${pageTitle} - ${title}`
    }
    return `${title}`
  }
}
